import { useEffect } from "react";
import { useVisualTest } from "@/context/VisualTestContext";
import "./VisualTest.scss";
import Completed from "@/containers/VisualTest/Completed/Completed";
import Progress from "@/containers/VisualTest/Progress/Progress";
import { usePathname } from "next/navigation";
import Link from "next/link";
import GroupComponent from "@/containers/VisualTest/GroupComponent/GroupComponent";

function VisualTest() {
  const { groups, fetchGroups, step } = useVisualTest();
  const pathname = usePathname();
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return (
    <>
      {pathname === "/" && (
        <div className="emm-main-title-container title-container home">
          <h2 className="text-center titleVidualTest">Try the EMM algorithm</h2>
        </div>
      )}
      {pathname === "/EMM" && (
        <div className="emm-main-title-container ">
          <h2 className="text-center">EMM purpose</h2>
        </div>
      )}
      {pathname === "/EMM" && (
        <div className="emm-paragraph-container">
          <p>
            This method originated from a program, developed for “PTSD and
            depressive conditions” treatment, mostly for military needs, but it
            also demonstrated high results for all recipients who experienced
            traumatic events. During the independent tests, there were
            discovered interesting and stable useful effects for a healthy
            audience with no disorders of any type. The main of them include:
          </p>
          <ol>
            <li>
              {" "}
              A boost of new ideas, related to lifestyle, business,
              relationships, entertainment and other kinds of daily activities.
            </li>
            <li> Inspiration for the current work/educational tasks.</li>
            <li> An enhancement of the short-term creative potential.</li>
          </ol>
          <p>
            Often, people describe their impressions as “it’s like the Universe
            itself talks to you through humanity, guiding you directly to what
            you’re seeking right now”. This resource is created to achieve
            larger amounts of feedback and real- world results through the
            flexible and multifunctional platform. It’s free for everyone; You
            can thank the resource by uploading new pictures or sending the link
            to your friends. The algorithm is constantly getting better.
          </p>
        </div>
      )}
      <div className={`title-container ${step === "final" ? "final" : ""}`}>
        <h2
          className={`p-0 m-0 show titleVidualTest ${
            step === "final" ? "text-center" : ""
          }`}
        >
          {step === "final"
            ? "Completed"
            : "Please choose one of the following:"}{" "}
        </h2>
      </div>

      {step !== "final" && <Progress className="responsive" />}
      <section className="fila">
        {step !== "final" && (
          <span className="columnaP">
            <Progress className="" />
          </span>
        )}

        {step !== "final" && (
          <span className="columna">
            {Object.keys(groups).map((group: string, i: number) => (
              <span key={`group-${i}`}>
                <GroupComponent group={group} />
              </span>
            ))}
          </span>
        )}
      </section>
      {step === "final" && <Completed />}
      {step !== "final" && pathname === "/" && (
        <div className="emm-paragraph-container">
          <p>
            After the quick 4-click visual test, the system will self-adjust
            following your current mindset, emotional needs, and some other
            factors. You’ll see a feed of Recommended galleries, related to your
            personality and today’s state of mind. Some of the photos inside are
            selected by the algorithm, others – uploaded by regular users. What
            you’ll have to do, is to follow the signs that are visible only to
            yourself: browse the feed and look for the galleries that you like,
            or for those that ignite your interest, or if they are connected to
            your previous life events and experience. When you open a gallery
            that you’re interested in, you’re able to see a smooth story of the
            person, through all the stages of their evolution and life
            challenges. Some of these photos carry pieces of information,
            directly related to your current emotional needs: it can be the
            context, outfit, or other types of interactions. Listen to your
            feelings and reactions to connect the dots.
          </p>
          <Link href={"/EMM"}>Learn more about EMM ( 1-minute read )</Link>
        </div>
      )}
    </>
  );
}

export default VisualTest;
