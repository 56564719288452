import { useVisualTest, OptionGroupI } from "@/context/VisualTestContext";
import "./GroupComponent.scss";
import OptionGroupComponent from "../OptionGroupComponent/OptionGroupComponenr";

interface GroupPropsI {
  group: string;
}

function GroupComponent(props: GroupPropsI) {
  const { group } = props;
  const { groups, step, convertSteps } = useVisualTest();

  const groupEnTurno: OptionGroupI[] = groups[group as keyof typeof groups];

  return (
    <>
      {step === group && (
        <div className="containerGroup">
          <h2>{`Group ${convertSteps(group)}`}</h2>
          <div className={"group-row"} style={{ marginTop: "2rem" }}>
            {" "}
            {/* ToDo: esto se va a quitar */}
            {groupEnTurno.length > 0 &&
              groupEnTurno.map((item: OptionGroupI, j: number) => (
                <span key={`OptionGroupI-${j}`}>
                  <OptionGroupComponent
                    index={item.index}
                    group={group}
                    url={item.url}
                    selected={item.selected}
                  />
                </span>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default GroupComponent;
