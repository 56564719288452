import { useVisualTest, OptionGroupI } from "@/context/VisualTestContext";
import "./OptionGroupComponent.scss";

function OptionGroupComponent(props: OptionGroupI) {
  const { setSelectedOption } = useVisualTest();
  const { group, index, url, selected } = props;

  const handleSelect = () => {
    setSelectedOption(group, index, !selected);
  };

  return (
    <>
      <div className="img-container" onClick={handleSelect}>
        {url !== "" ? (
          <img
            className={` ${selected && "selectOption"} imgOption`}
            src={url}
          />
        ) : (
          <div
            style={{
              fontSize: "5rem",
              border: "solid 1px white",
              display: "grid",
              justifyContent: "center",
              alignContent: "center",
              cursor: "pointer",
            }}
            className={` ${selected && "selectOption"} imgOption`}
          >
            {index + 1}
          </div>
        )}
      </div>
    </>
  );
}

export default OptionGroupComponent;
