import personalizeTemp from "@/utils/personalizeTemp";
import { createContext, useCallback, useContext, useState } from "react";

export interface SelectedOptionI {
  url: string;
  group: string;
  index: number;
}

export interface OptionGroupI {
  url: string;
  group: string;
  index: number;
  selected: boolean;
}

export interface GroupI {
  group1: OptionGroupI[];
  group2: OptionGroupI[];
  group3: OptionGroupI[];
  group4: OptionGroupI[];
}

const initialGroupos: GroupI = {
  group1: [],
  group2: [],
  group3: [],
  group4: [],
};

interface VisualTestContextI {
  groups: GroupI;
  // selecteds: SelectedOptionI[];
  step: string;

  setSelectedOption: (group: string, index: number, selected: boolean) => void;
  convertSteps: (_group: string) => number;
  fetchGroups: () => void;
}

export const initialVisualTestContext: VisualTestContextI = {
  groups: initialGroupos,
  // selecteds: [],
  step: "",

  setSelectedOption: (group: string, index: number, selected: boolean) =>
    console.log(group, index, selected),
  convertSteps: (_group: string) => 0,

  fetchGroups: () => console.log("fetchGroupos"),
};

export const VisualTestContext = createContext<VisualTestContextI>(
  initialVisualTestContext
);

export const useVisualTest = () => {
  return useContext(VisualTestContext);
};

function VisualTestProvider({ children }: { children: React.ReactNode }) {
  const [groups, setGroups] = useState<GroupI>(initialGroupos);
  // const [ selecteds, setSelecteds ] = useState<SelectedOptionI[]>( [] )
  const [step, setStep] = useState<string>("group1");

  const fetchGroups = useCallback(() => {
    let diferente = false;
    const arreglo: number[] = [];

    while (!diferente) {
      const index = Math.round(Math.random() * (personalizeTemp.length - 1));
      if (!arreglo.includes(index)) {
        arreglo.push(index);
      }
      if (arreglo.length === 4) {
        diferente = true;
      }
    }

    for (let i = 0; i < arreglo.length; i++) {
      const index = arreglo[i];

      const images: any = personalizeTemp[index].images;
      const longitud = images.length;
      let arraly: OptionGroupI[] = [];
      for (let j = 0; j < longitud; j++) {
        arraly.push({
          url: images[j].url,
          group: `group${i + 1}`,
          index: j,
          selected: false,
        });
      }
      setGroups((prev: GroupI) => ({ ...prev, [`group${i + 1}`]: arraly }));
    }
  }, []);

  const convertSteps = (_group: string) => {
    switch (_group) {
      case "group1":
        return 1;
      case "group2":
        return 2;
      case "group3":
        return 3;
      case "group4":
        return 4;
      default:
        throw Error("entro el default en stablishSteps!!!");
    }
  };

  const nextStep = () => {
    switch (step) {
      case "group1":
        setStep("group2");
        break;
      case "group2":
        setStep("group3");
        break;
      case "group3":
        setStep("group4");
        break;
      case "group4":
        setStep("final");
        break;
      default:
        throw Error("entro el default en nextStep!!!");
    }
  };

  const setSelectedOption = (
    _group: string,
    _index: number,
    _selected: boolean
  ) => {
    const groupsTemp: GroupI = { ...groups };
    // const selectedsTemp: SelectedOptionI[] = [ ...selecteds ]

    groupsTemp[_group as keyof typeof groups].map((item: OptionGroupI) => {
      item.selected = false;
      return item;
    });
    groupsTemp[_group as keyof typeof groups][_index].selected = _selected;

    // const indexF = selectedsTemp.findIndex( ( item: SelectedOptionI ) => item.group === _group )

    // const auxArrayli:SelectedOptionI[] = []
    // selectedsTemp.forEach( ( item, i ) => {
    //     i !== indexF && auxArrayli.push( item )
    // } )
    // stablishSteps( _group )
    // setSelecteds( auxArrayli )

    setGroups(groupsTemp);
    setTimeout(nextStep, 500);
  };

  const values: VisualTestContextI = {
    groups,
    // selecteds,
    step,

    setSelectedOption,
    convertSteps,
    fetchGroups,
  };

  return (
    <VisualTestContext.Provider value={values}>
      {children}
    </VisualTestContext.Provider>
  );
}

export default VisualTestProvider;
