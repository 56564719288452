import { useEffect, useRef, useState } from "react";

import "./Completed.scss";
import { generateRandomString } from "@/utils/Functions/RandomString";
import { useRouter } from "next/navigation";

const wordsArray = ["Mood", "Mindset", "Character", "Emotional pattern"];

const NameCompleted = (props: any) => {
  const { step, handleReady, time } = props;
  const [loading, setLoading] = useState<string>("");
  const [ready, setReady] = useState<string>("");
  const dot = ".";
  const loadingRef = useRef<HTMLDivElement>(null);
  const [maxDots, setMaxDots] = useState(20);

  useEffect(() => {
    if (loadingRef.current) {
      const loadingWidth = loadingRef.current.offsetWidth;
      const dotWidth = 8.5; // Adjust based on the actual width of a dot
      setMaxDots(Math.floor(loadingWidth / dotWidth));
    }
  }, [loadingRef.current]);

  useEffect(() => {
    if (loading.length < maxDots) {
      setTimeout(() => setLoading((prev) => prev.concat(dot)), time);
    } else if (loading.length >= maxDots) {
      setReady("Ready");
      if (step !== undefined) handleReady(step + 1);
    }
  }, [loading, maxDots]);

  return (
    <>
      <div className="visualTests">
        <p className="m-0">{wordsArray[step]}</p>
        <span className="loading" ref={loadingRef}>
          {loading}
        </span>
        <span className="ready"> {ready}</span>
      </div>
    </>
  );
};

function Completed() {
  const router = useRouter();
  const [listNames, setListNames] = useState<any[]>([]);
  const [btnReady, setBtnReady] = useState<boolean>(false);

  useEffect(() => {
    if (listNames.length < 4) {
      for (let i = 0; i < 4; i++) {
        const milSegs = 50;
        const segs = milSegs / 25;

        setListNames((prev: any) => [...prev, { segs, ready: false }]);
      }
    }
  }, []);

  const handleClick = () => {
    const randomString = generateRandomString(20);
    localStorage.setItem("extension", randomString);
    router.push(
      `/?c=${randomString}`
      // { state: { fragment: "recomended" } }
    );
  };

  const handleReady = (nextStep: number) => {
    const aux = [...listNames];
    if (nextStep === 4) {
      setBtnReady(true);
    } else {
      aux[nextStep].ready = true;
      setListNames(aux);
    }
  };

  return (
    <section className="Completed">
      <div className="wrap">
        {listNames.length > 0 && (
          <NameCompleted
            step={0}
            handleReady={handleReady}
            time={listNames[0].segs}
          />
        )}
        {listNames.length > 0 && listNames[1].ready && (
          <NameCompleted
            step={1}
            handleReady={handleReady}
            time={listNames[1].segs}
          />
        )}
        {listNames.length > 0 && listNames[2].ready && (
          <NameCompleted
            step={2}
            handleReady={handleReady}
            time={listNames[2].segs}
          />
        )}
        {listNames.length > 0 && listNames[3].ready && (
          <NameCompleted
            step={3}
            handleReady={handleReady}
            time={listNames[3].segs}
          />
        )}
        <div className="toStart">
          {listNames.length > 0 && btnReady && (
            <button className="btn linear-bg" onClick={handleClick}>
              Press here to Start
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

export default Completed;
