import "./Proccess.scss";
import { useVisualTest } from "@/context/VisualTestContext";

interface ProgressI {
  className: string;
}

function Progress({ className = "" }: ProgressI) {
  const { step, convertSteps } = useVisualTest();

  const getWidth = (_step: number) => {
    switch (_step) {
      case 1:
        return "step1";
      case 2:
        return "step2";
      case 3:
        return "step3";
      case 4:
        return "step4";
      default:
        return "step1";
    }
  };

  return (
    <section className={` ProgressComponent ${className}`}>
      <div className="wrapProgress">
        <div className="progress">
          <span className={`${getWidth(convertSteps(step))} bar`} />
          <span
            className={`${
              convertSteps(step) >= 2 ? "fadeOut" : ""
            } dot step1Dot `}
          />
          <span
            className={`${
              convertSteps(step) >= 3 ? "fadeOut" : ""
            } dot step2Dot`}
          />
          <span
            className={`${
              convertSteps(step) >= 4 ? "fadeOut" : ""
            } dot step3Dot`}
          />
        </div>
      </div>

      <div className="steps">{convertSteps(step)} of 4</div>
    </section>
  );
}

export default Progress;
